// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// font-family: "Roboto", sans-serif;
$font-stack: "Roboto", "Open Sans", "Helvetica Neue Light", "Helvetica Neue",
  "Helvetica", "Arial", sans-serif !default;

//Colors

$red: #b61f24;
$form_text_color: #a9a9a9;
//Typography

$h1_size: 70px;
$h2_size: 45px;
$h3_size: 30px;
$h4_size: 25px;

$p_size: 18px;
$p_height: 1.6;
$p_weight: 300;

$form_size: 18px;

//Otheer

$input_shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
$box_shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
$form_border_radius: 5px;
$headerHeight: 100px;
// filter

$filter: drop-shadow(0px 0px 20px rgba(38, 38, 38, 0.4));
