header {
  height: 100px;
  min-height: 100px;
  max-height: 100px;
  @include flex(row, center, center);
  .header--wrapper {
    position: relative;
    width: 100%;
    height: 100px;
    @include flex(row, space-between, center);
    background: white;
    button {
      display: none !important;
    }
    .header--logo {
      margin-left: -8px;
      width: 180px;
      pointer-events: none;
    }
    .header--main-nav {
      @include flex(row, space-between, center);

      a {
        font-weight: 300;
        cursor: pointer;
        color: black;
        font-size: 20px;
        text-decoration: none;
        margin-right: 30px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          color: $red;
        }
      }
      .active {
        font-weight: bold;
        color: $red;
      }
    }
    .mobile--nav {
      background: white;
      @include flex(row, space-between, center);
      position: absolute;
      left: 0;
      right: 0;
      top: 100px;
      padding-top: 10px;
      padding-bottom: 30px;
      box-shadow: 0 4px 2px -2px gray;
      .nav--left {
        flex-basis: 70%;
        background: white;
        color: white;
        @include flex(column, space-between, flex-start);
        h3 {
          color: black;
        }
        a {
          font-weight: 300;
          cursor: pointer;
          color: black;
          font-size: 20px;
          text-decoration: none;
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            color: $red;
          }
        }
        .active {
          font-weight: bold;
          color: $red;
        }
      }
      .nav--right {
        p {
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  header {
    .header--wrapper {
      button {
        display: flex !important;
      }
      .header--logo {
        width: 150px;
      }
      .header--main-nav {
        display: none;
      }

      button {
        display: flex;
        margin-top: 10px;
        // width: 30px;
        // height: 30px;
        .BurgerBox {
          // width: 25px;
          // height: 30px;
          // background-color: $red;

          .BurgerInner {
            background-color: $red;

            &::before {
              top: -7px;
              background-color: $red;
            }
            &::after {
              background-color: $red;
              bottom: -7px;
            }
          }
        }
      }
      .BurgerActive {
        .BurgerBox {
          .BurgerInner {
            &::before {
              top: 0px;
            }
            &::after {
              bottom: 0px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 520px) {
  header {
    .header--wrapper {
      .mobile--nav {
        .nav--right {
          display: none;
        }
        .nav--left {
          flex-basis: 100%;
          align-items: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  header {
    .header--wrapper {
      .header--logo {
        width: 150px;
      }
    }
  }
}
