.picture {
  position: relative;
  flex-basis: 60%;
  @include flex(column, center, center);
  margin-right: -100px;
  .picture--white {
    width: 500px;
    z-index: 2;
    svg {
      filter: $filter;
      path {
        fill: white;
      }
    }
  }
  .picture--red {
    position: absolute;
    width: 500px;
    transform: rotate(15deg);
    z-index: 1;
    svg {
      filter: $filter;
      path {
        fill: $red;
      }
    }
  }
  .picture--inside {
    position: absolute;
    z-index: 3;
    filter: $filter;
    transform: scale(0.5, 0.5);
    padding-bottom: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .picture {
    flex-basis: 100%;
    margin-top: 120px;
    margin-bottom: 40px;
    .picture--white {
      width: 500px;
    }
    .picture--red {
      position: absolute;
      width: 500px;
      transform: rotate(15deg);
      z-index: 1;
      svg {
        filter: $filter;
        path {
          fill: $red;
        }
      }
    }
    .picture--inside {
      position: absolute;
      z-index: 3;
      filter: $filter;
      transform: scale(0.5, 0.5);
      padding-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 660px) {
  .picture {
    .picture--white {
      width: 400px;
    }
    .picture--red {
      width: 400px;
    }
    .picture--inside {
      transform: scale(0.4, 0.4);
    }
  }
}

@media only screen and (max-width: 425px) {
  .picture {
    .picture--white {
      width: 300px;
    }
    .picture--red {
      width: 270px;
    }
    .picture--inside {
      transform: scale(0.3, 0.3);
    }
  }
}

@media only screen and (max-width: 320px) {
  .picture {
    .picture--white {
      width: 250px;
    }
    .picture--red {
      width: 250px;
    }
    .picture--inside {
      transform: scale(0.25, 0.25);
    }
  }
}
