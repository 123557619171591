.home-preporuke {
  background: $red;
  color: white;
  margin-top: 200px;
  padding-right: 220px;
  @include flex(row, space-between, flex-start);
  .home-preporuke--left {
    padding-top: 100px;
    flex-basis: 45%;
    .home-preporuke--txt {
      width: 445px;
    }
    .home-preporuke--content {
      @include flex(row, space-between, flex-start);
      position: relative;
      .home-preporuke--nav {
        .active {
          .iconComponent {
            .icon {
              transition: transform 0.5s;
              background: $red;
            }
          }
        }
        .icon--container.active {
          align-self: flex-start;
          background: $red;
          font-weight: bold;
          transform: scale(1.2);
          transform-origin: left;

          .iconComponent {
            .icon {
              border: 1px solid white;
              background: $red;
              svg {
                path {
                  fill: white;
                }
              }
            }
          }
        }
        .icon--container {
          @include flex(row, flex-start, center);
          margin-bottom: 30px;
          .iconComponent {
            margin-right: 30px;
            .icon {
              cursor: pointer;
              transition: transform 0.5s;
              background: white;
              border-radius: 39px;
              width: 78px;
              height: 78px;
              @include flex(row, center, center);
              svg {
                height: 48px;
                path {
                  fill: $red;
                }
              }
            }
          }
          .icon-text {
            cursor: pointer;
            flex-basis: 45%;
          }
        }
      }
    }
  }
  .home-preporuke--right {
    flex-basis: 55%;
    position: relative;
    align-self: stretch;
    @include flex(column, center, flex-start);
    .home-preporuke--side-text {
      padding-left: 100px;
      flex-basis: 45%;
      z-index: 225;
      color: #000;
      @include flex(column, center, flex-start);
      .iconComponent {
        .icon {
          svg {
            margin-bottom: 25px;
            fill: $red;
            height: 48px;
          }
        }
      }
      svg {
        margin-right: 15px;
        fill: $red;
      }

      p {
        line-height: 1.67;
        letter-spacing: 0.5px;
        margin-bottom: 30px;
        list-style-type: none;
      }
      h3 {
        color: $red;
        // padding: 50px;
      }
    }
    .home-preporuke--triangle {
      height: 100%; //must stay 100%
      position: absolute;
      width: 145%;
      top: 0;
      left: 0;
      svg {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        filter: $filter;
        path {
          fill: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: 1830px) {
  .home-preporuke--triangle {
    width: 165% !important;
  }
}
@media only screen and (max-width: 1700px) {
  .home-preporuke--triangle {
    width: 180% !important;
  }
}

@media only screen and (max-width: 1200px) {
  //custom component
  .home-preporuke {
    display: none;
  }
}
