.contact {
  margin-top: 150px;
  margin-bottom: 150px;
  @include flex(column, space-between, center);
  // background-image: url("../assets/images/contact.png");
  // background-repeat: no-repeat;
  // background-position: 120% 60%;
  h3 {
    line-height: 1.17;
    letter-spacing: 0.9px;
  }
  p {
    font-size: 18px;
    line-height: 1.67;
    letter-spacing: 0.5px;
  }
  form {
    width: 100%;
  }
  .contact--main {
    margin-top: 200px;
    @include flex(row, space-between, flex-start);
    .contact--text {
      @include flex(column, flex-start, flex-start);
      flex-basis: 40%;
      a {
        color: $red;
        text-decoration: none;
      }
    }
    .contact--info {
      align-self: stretch;
      @include flex(column, flex-start, stretch);
      position: relative;
      > div {
        height: 100%;
        align-self: stretch;
        @include flex(column, flex-start, stretch);
        p {
          flex: 1;
          flex-basis: 16.6%;
          margin-bottom: 0;
          i {
            margin-right: 20px;
            color: $red;
          }
          .fa-mobile-alt {
            font-size: 25px;
          }
          a {
            color: #000;
            text-decoration: none;
          }
        }
      }
    }
  }
  .backgroundImage {
    position: absolute;
    top: 50%;
    left: 87%;
  }
}

@media only screen and (max-width: 1550px) {
  // .contact {
  //   background-position: 120% 10%;
  // }
}

@media only screen and (max-width: 1350px) {
  .backgroundImage {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .contact {
    background-position: -80% -10%;
    margin-top: 10px;
    margin-bottom: 50px;

    .contact--main {
      margin-top: 50px;
      @include flex(column, center, center);
      text-align: center;
      .contact--text {
        @include flex(column, center, center);
        flex-basis: 40%;
      }
      .contact--info {
        > div {
          p {
            margin-bottom: 10px;
            i {
              margin-right: 20px;
              color: $red;
            }
            .fa-mobile-alt {
              font-size: 25px;
            }
          }
        }
      }
    }
  }
}
