// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700");

html {
  font-family: $font-stack;
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
}

section {
  display: flex;
}

form {
  input {
    &::placeholder {
      color: $form_text_color;
    }
    height: 60px;
    border-radius: $form_border_radius;
    box-shadow: $input_shadow;
    margin-bottom: 20px;
    border: 0px;
    font-size: $form_size;
    padding-left: 15px;
    &:focus {
      outline: none;
    }
  }
  textarea {
    &::placeholder {
      color: $form_text_color;
    }
    border-radius: $form_border_radius;
    box-shadow: $input_shadow;
    border: 0px;
    font-size: $form_size;
    padding-top: 15px;
    padding-left: 15px;
    // padding-top: 10px;
    // padding-right: 15px;
    &:focus {
      outline: none;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h1 {
  margin-top: 0;
  font-size: $h1_size;
  margin-bottom: 60px;
}

h2 {
  margin-top: 0;
  font-size: $h2_size;
}

h3 {
  margin-top: 0;
  font-size: $h3_size;
}

h4 {
  margin-top: 0;
  font-size: $h4_size;
}

p {
  letter-spacing: 0.6px;
  line-height: $p_height;
  font-weight: $p_weight;
  font-size: $p_size;
  margin-top: 0;
  margin-bottom: 30px;
}

body {
}

main {
  display: flex;
  flex-direction: column;
}
/*no scrollbar*/
// ::-webkit-scrollbar {
//   display: none;
// }
