@charset 'UTF-8';

// 1. Configuration and helpers
@import "abstracts/variables", "abstracts/functions", "abstracts/mixins";

// 2. Vendors
@import "vendors/normalize";

// 3. Base stuff
@import "base/base", "base/fonts", "base/typography", "base/helpers";

// 5. Components
@import "../components/Header/styles.scss";
@import "../components/Footer/styles.scss";
@import "../components/HomeCover/styles.scss";
@import "../components/HomeUsluge/styles.scss";
@import "../components/HomePreporuke/styles.scss";
@import "../components/HomeLab/styles.scss";
@import "../components/HomeForm/styles.scss";
@import "../components/_FormComponents/Button/styles.scss";
@import "../components/_FormComponents/ContactForm/styles.scss";
@import "../components/_FormComponents/MenuIcon/styles.scss";
@import "../components/Picture/styles.scss";
@import "../components/Pricelist/styles.scss";
@import "../components/HomeUslugeResponsive/styles.scss";
@import "../components/Accordion/styles.scss";
@import "../components/HomePreporukeResponsive/styles.scss";

//pages
@import "../pages/Contact/styles.scss";
@import "../pages/About/styles.scss";
@import "../pages/Location/styles.scss";

@media only screen and (max-width: 1550px) {
  .main-padding {
    padding: 0 150px;
  }
}

@media only screen and (max-width: 1350px) {
  .main-padding {
    padding: 0 75px;
  }
}

@media only screen and (max-width: 1024px) {
  .main-padding {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 768px) {
  .main-padding {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 425px) {
  .main-padding {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 320px) {
  .main-padding {
    padding: 0 20px;
  }
}
