// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.red {
  background: $red;
}

.main-padding {
  padding: 0 300px;
}

.number {
  color: $red;
  font-weight: bold;
}
