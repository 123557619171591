.homeUsluge {
  display: none;
  .backgroundImage {
    position: absolute;
    right: -15%;
  }
  #homeUsluge--naslov {
    color: $red;
  }

  .homeUsluge--content {
    @include flex(row, left, center);
    background-color: #fff;
    .backgroundImage {
      // position: absolute;
      left: 3%;
      z-index: -225;
    }
    .homeUsluge--list {
      width: 480px;
      border: 5px solid #989898;
      border-radius: 10px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      li {
        font-size: 18px;
        padding-left: 30px;
        padding-bottom: 20px;
        list-style-type: none;
        &:first-child {
          padding-top: 30px;
        }
        &:last-child {
          padding-bottom: 30px;
        }
        &:hover {
          color: $red;
          cursor: pointer;
        }
      }

      li:before {
        display: inline-block;
        margin-left: -1.3em;
        width: 1.3em;
      }
    }

    .homeUsluge--info {
      padding-left: 160px;
      width: 480px;
      p {
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: 0.5px;
        padding-bottom: 90px;
      }
      h3 {
        width: 320px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: 0.9px;
      }
      a {
        text-decoration: none;
        color: $red;
      }
    }
  }
}

#homeUsluge--logo {
  pointer-events: none;
}

@media only screen and (max-width: 1300px) {
  .homeUsluge {
    display: none;
  }
}
