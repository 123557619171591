.maps--container {
  @include flex(row, space-between, flex-start);
  margin-top: 100px;
  margin-bottom: 50px;
  .maps--box {
    @include flex(column, flex-start, flex-start);
    flex-basis: 48%;
    #gmap_canvas {
      width: 100%;
      height: 540px;
      margin-right: 20px;
      box-shadow: $box_shadow;
      &:last-child {
        margin-right: 0;
      }
    }
    .text {
      margin-top: 50px;
      p {
        margin-bottom: 15px;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .maps--container {
    margin-top: 50px;
    @include flex(column, space-between, flex-start);
    .maps--box {
      flex-basis: 100%;
      width: 100%;
      margin-bottom: 25px;

      #gmap_canvas {
        margin-right: 0px;
        height: 340px;
      }
    }
  }
}

@media only screen and (max-width: 660px) {
  .maps--container {
    .maps--box {
      text-align: center;
      @include flex(column, space-between, center);
    }
  }
}
