.footer {
  border-top: 1px solid #e8e7e7;
  .main--footer {
    @include flex(row, space-between, flex-start);
    padding-top: 130px;
    padding-bottom: 130px;
    .footer--info {
      @include flex(column, center, center);
      span:nth-child(2) {
      }
      h3 {
        margin-bottom: 10px;
      }
      span {
        &:nth-child(3) {
          margin-bottom: 25px;
        }
        &:nth-child(5) {
          margin-bottom: 25px;
        }
      }
      a {
        &:nth-child(7) {
          //margin-bottom: 25px;
        }
      }

      .email {
        margin-top: 25px;
      }
    }
    ul {
      padding-left: 0;
      margin-top: 0;
      li {
        line-height: 1.5;
        letter-spacing: 0.5px;
        list-style-type: none;

        a {
          color: #000;
          text-decoration: none;
        }
      }
    }
    .footer--docs {
      @include flex(row, flex-end, center);
      flex-wrap: wrap;
      a {
        text-decoration: none;
        color: #000;
      }
      .footer--docs--box {
        flex-basis: 30%;
        margin-left: 20px;
      }
      .button {
        flex-basis: 65%;
        margin-top: 140px;
      }
    }
  }
  .bottom--footer {
    @include flex(row, space-between, center);
    p,
    a {
      margin-bottom: 9px;
      font-size: 14px;
      color: $red;
      letter-spacing: 0.4px;
      text-decoration: none;
    }
    span {
      color: #000;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .footer {
    .main--footer {
      flex-wrap: wrap;
      .footer--info {
        flex-basis: 50%;
        text-align: center;
      }
      .footer--docs {
        flex-basis: 100%;
        justify-content: center;
        .footer--docs--box {
          flex-basis: 50%;
          margin-left: 0;
          text-align: center;
          margin-top: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    .main--footer {
      text-align: center;
      @include flex(column, center, center);
      padding-top: 80px;
      padding-bottom: 80px;
      .footer--info:nth-child(2) {
        margin-top: 45px;
      }
      .footer--docs {
        @include flex(row, center, center);
        margin-top: 60px;

        .button {
          margin-top: 40px;
        }
      }
    }
    .bottom--footer {
      @include flex(row, space-between, center);
    }
  }
}
