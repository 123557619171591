//zaokruzene vrednosti
// box-shadow preko vars => $box_shadow
// border-radius opet vars
// input field i textarear treba stajlovati u base.scss

.contact--form {
  @include flex(column, center, stretch);
  .form--wrapper {
    @include flex(row, space-between, stretch);
    margin-bottom: 20px;
    .form--row {
      width: 100%;
      @include flex(column, center, center);
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
      input.error {
        border: 1px solid $red;
      }
      input {
        align-self: stretch;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    textarea.error {
      border: 1px solid $red;
    }
    textarea {
      resize: none;
      align-self: stretch;
      height: 100%;
    }
  }
  .buttons {
    @include flex(row, flex-end, center);
    .message {
      color: $red;
      margin-right: 50px;
      .success {
        color: black;
      }
    }
    .button {
      align-self: flex-end;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .contact--form {
    @include flex(column, center, stretch);
    .form--wrapper {
      @include flex(column, space-between, stretch);
      margin-bottom: 20px;
      .form--row {
        @include flex(column, center, center);
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
        input {
          align-self: stretch;
          &:last-child {
            margin-bottom: 0;
          }
        }
        textarea {
          resize: none;
          align-self: stretch;
          height: 150px;
        }
      }
    }
    .buttons {
      @include flex(column, center, center);
      .message {
        margin-bottom: 30px;
      }
      .button {
        align-self: center;
      }
    }
  }
}
