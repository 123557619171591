.home-preporukeResponsive {
  display: none;
  background-color: $red;
  color: white;
  margin-top: 100px;
  .home-preporuke--left {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .home-preporuke--content {
    .home-preporuke--nav {
      .icon--container {
        .iconComponent {
          .icon {
            background-color: #fff;
          }
        }
      }
      p {
        margin-bottom: 15px;
        font-size: 15px;
      }
      svg {
        fill: #fff;
        margin-right: 15px;
        height: 15px;
      }
      .accordion--header {
        @include flex(row, flex-start, center);
        // width: 80%;
        margin-bottom: 30px;
        p {
          order: 1;
          margin-bottom: 0;
          // flex-basis: 50%;
          font-size: 18px;
          cursor: pointer;
        }
        i {
          background-color: #fff;
          width: 78px;
          height: 78px;
          @include flex(row, center, center);
          border-radius: 50%;
          margin-right: 25px;
          cursor: pointer;
        }
        svg {
          height: 50px;
          fill: $red;
          margin-right: 0;
        }
      }
      .accordion--recommend {
        margin-bottom: 50px;
        margin-top: 25px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .home-preporukeResponsive {
    display: flex;
    .home-preporuke--left {
      .home-preporuke--txt {
        text-align: left;
      }
      .home-preporuke--content {
        .home-preporuke--nav {
          .accordion--header {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-preporukeResponsive {
    .home-preporuke--left {
      h2 {
        text-align: left;
      }
      .home-preporuke--txt {
        margin-bottom: 100px;
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .home-preporukeResponsive {
    .home-preporuke--left {
      .home-preporuke--content {
        .home-preporuke--nav {
          .accordion--header {
            p {
              flex-basis: 50%;
            }
          }
        }
      }
    }
  }
}
