.homeLab {
  margin-top: 150px;
  margin-bottom: 70px;
  @include flex(row, space-between, center);
  .homeLab--content {
    flex-basis: 60%;
    // width: 555px;
    h1 {
      color: $red;
    }
    h3 {
      font-weight: normal;
    }
    p {
      padding-top: 20px;
      font-size: 18px;
    }
    a {
      text-decoration: none;
    }
  }
  .picture {
    margin: 0 -100px;
    flex-basis: 40%;
    height: 600px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 1024px) {
  .homeLab {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 70px;
    @include flex(column-reverse, space-between, center);
    .picture {
      margin: 0 -100px;
      margin-top: 50px;
      flex-basis: 40%;
      height: 300px !important;
      width: 100% !important;
    }
    .homeLab--content {
      margin-left: 0; // width: 555px;
      h1 {
        color: $red;
      }
      h3 {
        font-weight: normal;
      }
      p {
        padding-top: 20px;
        font-size: 18px;
      }
      .button {
        margin: 0 auto;
      }
    }
  }
}
