.button {
  height: 70px;
  width: 260px;
  font-weight: bold;
  font-size: 20px;
  color: white;
  border-radius: 40px;
  box-shadow: $box_shadow;
  @include flex(column, center, center);
  cursor: pointer;
}
