.price--list--open--container {
  background-color: $form_text_color;
  // border-radius: 10px;
  overflow: auto;
  // height: 890px;
  padding: 10px;
  flex-basis: 40%;
  .price--list--open {
    @include flex(row, space-between, center);
    span {
      padding-top: 20px;
      padding-left: 30px;
      padding-right: 20px;
      color: #fff;
      &:hover {
        color: $red;
      }
    }
  }
}
