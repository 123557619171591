.about {
  margin-top: 80px;
  margin-bottom: 80px;
  @include flex(row, space-between, center);
  .picture {
    height: 800px !important;
    margin: 0 -50px;
  }
  .about--text {
    flex-basis: 40%;
    h1 {
      color: $red;
      margin-bottom: 0;
    }
    h3 {
      color: $red;
    }
    .backgroundImage {
      position: absolute;
      left: -15%;
    }
  }
}

@media only screen and (max-width: 1550px) {
  .backgroundImage {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .about {
    margin-top: 50px;
    margin-bottom: 50px;
    @include flex(column, center, center);
    text-align: center;
    .picture {
      height: 300px !important;
      width: 100%;
    }
  }
}
