.homeUslugeResponsive {
  @include flex(column, flex-start, flex-start);
  .backgroundImage {
    position: absolute;
    right: -15%;
  }
  h2 {
    color: $red;
  }
  .homeUsluge--content {
    @include flex(row, left, flex-start);
    .backgroundImage {
      position: absolute;
      left: 3%;
      z-index: -225;
      bottom: -95%;
    }
    .price--list--open--container {
      background-color: #fff;
      height: 250px;
      span {
        color: #000;
        font-size: 15px;
        padding-left: 0;
        padding-right: 0;
        flex-basis: 50%;
        &:nth-child(2) {
          text-align: end;
        }
      }
    }
    .homeUsluge--list {
      .icon--container {
        &:hover {
          background-color: $red;
          p {
            color: #fff;
          }
          svg {
            fill: #fff;
          }
        }
      }
      .icon--container.active {
        background-color: $red;
        p {
          color: #fff;
        }
        svg {
          fill: #fff;
          transform: rotate(90deg);
        }
      }
      .accordion--header {
        color: #000;
        @include flex(row, space-between, center);
        border: 1px solid rgba(59, 29, 29, 0.1);
        padding: 10px;
        i {
          font-size: 10px;
        }
        h3 {
          font-size: 18px;
        }
        p {
          margin-bottom: 0;
        }
        svg {
          fill: $red;
        }
      }
      border: 1px solid rgba(0, 0, 0, 0.1);
      flex-basis: 80%;
      background-color: #fff;
      padding: 0;
      li {
        font-size: 18px;
        list-style-type: none;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .homeUsluge--info {
      padding-left: 160px;
      p {
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: 0.5px;
        padding-bottom: 90px;
      }
      h3 {
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: 0.9px;
      }
      a {
        text-decoration: none;
        color: $red;
      }
    }
  }
}

#homeUsluge--logo {
  pointer-events: none;
}

@media only screen and (max-width: 1200px) {
  .homeUslugeResponsive {
    // display: block;
    .homeUsluge--content {
      @include flex(column, center, center);
      .homeUsluge--list {
        align-self: flex-start;
        width: 100%;
      }
      .homeUsluge--info {
        padding-left: 0;
        margin-top: 55px;
        h3 {
          padding-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .homeUslugeResponsive {
    margin-top: 80px;
    h1,
    h3 {
      text-align: center;
    }
    .homeUsluge--content {
      .homeUsluge--list {
        align-self: center;
      }
      .homeUsluge--info {
        text-align: center;
        p {
          padding-bottom: 30px;
        }
        img {
          margin-right: -30px;
        }
      }
    }
  }
}
