.home--cover {
  height: calc(100vh - 100px);
  //height: auto;
  @include flex(row, space-between, center);
  .text {
    flex-basis: 40%;
    h1 {
      color: $red;
      margin-left: -4px;
      margin-bottom: 0px;
      font-size: 70px;
    }
    h3 {
      margin-bottom: 30px;
      color: $red;
      font-weight: 100;
    }
    p {
      margin-bottom: 40px;
    }
    a {
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .home--cover {
    @include flex(column, center, center);
    .text {
      flex-basis: inherit;
      text-align: center;
    }
    .picture {
      margin-top: 30px;
      margin-right: 0;
      height: 600px !important;
      width: 600px !important;
    }
    .button {
      margin: 0 auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .home--cover {
    height: auto;
    margin-top: 50px;
    .picture {
      display: none;
    }
  }
}

@media only screen and (max-width: 660px) {
  .home--cover {
    height: auto;
    margin-top: 50px;
    .picture {
      display: none;
    }
  }
}

@media only screen and (max-width: 320px) {
  .home--cover {
    margin-top: 50px;
    height: auto;
  }
}
